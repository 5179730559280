import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import "./what-we-offer.scss";

// import Button from "../components/button/button"
// import SectionHeading from "../components/section-heading/section-heading"
// import ContactSection from "../components/contact-section/contact-section"
import CappedWidth from "../components/capped-width/capped-width";
import ParagraphWithImage from "../components/paragraph-with-image/paragraph-with-image";

//Import images from image.js here:
// import { EllenWreath } from "../components/image"
import whatWeDo1 from "../images/a-community-of-writers-julia-joppien.jpg";
import whatWeDo2 from "../images/power-of-reviews-jaredd-craig.jpg";
import whatWeDo3 from "../images/professional-input-kat-stokes.jpg";

const WhatWeDoPage = () => (
  <Layout>
    <SEO title="What We Offer" />
    <div id="what-we-offer">
      {/* Why Join OWW */}
      <div className="section what-we-offer">
        <CappedWidth>
          <h1 className="page-title">What We Offer</h1>
          <h3 className="page-subtitle">
            The Online Writing Workshop for SF, F, & H is open to all writers of
            science fiction, fantasy, or horror, both aspiring and professional.
            Writers improve here through the reviews and ratings given their
            works by other writers, and through reviewing the work of others.
          </h3>
        </CappedWidth>
      </div>
      <ParagraphWithImage
        title="A Community of Writers"
        description="Like an in-person workshop, we are a community of writers who review each others’ work—sometimes paying back, sometimes paying forward, building skills by reviewing and being reviewed. Our moderated groups provide discussion, answers, and advice. Member-run events like the annual Crit Marathon and occasional contests are motivating and fun. Focus Groups delve into member-requested topics like synopsis writing, plotting, and book proposals."
        firstButton="Start Your Free Trial"
        firstButtonPath="/free-trial.shtml"
        secondButton="How it Works"
        secondButtonPath="/how-oww-works"
        image={whatWeDo1}
      />

      <ParagraphWithImage
        reversed
        title="The Power of Reviews"
        description="Insightful reviews power the workshop, so new reviewers get guidance, templates, even mentoring. Members find they learn as much from reviewing others as from receiving reviews—maybe more."
        firstButton="Start Your Free Trial"
        firstButtonPath="/free-trial.shtml"
        secondButton="Member Book Gallery"
        secondButtonPath="/member-book-gallery"
        image={whatWeDo2}
      />

      <ParagraphWithImage
        title="Professional Input"
        description="Insightful reviews power the workshop, so new reviewers get guidance, templates, even mentoring."
        firstButton="Start Your Free Trial"
        firstButtonPath="/free-trial.shtml"
        secondButton="Our Resident Editors"
        secondButtonPath="/who-we-are#resident-editors"
        image={whatWeDo3}
      />
    </div>
  </Layout>
);

export default WhatWeDoPage;
