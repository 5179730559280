import React from "react";
import PropTypes from "prop-types";

import "./paragraph-with-image.scss";

import Button from "../button/button";

// sub component
const ParagraphText = ({
  title,
  description,
  firstButton,
  secondButton,
  firstButtonPath,
  secondButtonPath,
}) => {
  return (
    <div className="paragraph-text-wrapper">
      <h2 className="paragraph-text-title">{title}</h2>
      <p className="paragraph-text-description">{description}</p>
      <div className="paragraph-text-buttons">
        {firstButton && (
          <Button
            color="purple"
            fill
            text={firstButton}
            path={firstButtonPath}
          />
        )}
        {secondButton && (
          <Button color="purple" text={secondButton} path={secondButtonPath} />
        )}
      </div>
    </div>
  );
};
const ParagraphImage = ({ image }) => {
  return (
    // <div
    //   className="paragraph-image-wrapper"
    //   style={{ backgroundImage: `url(${image})` }}
    // ></div>
    <div className="paragraph-image-wrapper">
      <img className="paragraph-image" src={image} alt="" />
    </div>
  );
};

// main component
const ParagraphWithImage = ({
  title,
  description,
  firstButton,
  secondButton,
  firstButtonPath,
  secondButtonPath,
  reversed,
  image,
}) => {
  return (
    <div className="paraghraph-with-image-wrapper">
      {reversed && <ParagraphImage image={image} />}
      <ParagraphText
        title={title}
        description={description}
        firstButton={firstButton}
        secondButton={secondButton}
        firstButtonPath={firstButtonPath}
        secondButtonPath={secondButtonPath}
      />
      {!reversed && <ParagraphImage image={image} />}
    </div>
  );
};

export default ParagraphWithImage;
